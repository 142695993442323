import React from "react";
import { Link } from "gatsby"
import Logo from "../images/logo.svg";
import IconLang from "../images/language_icon.svg"
import "../components/Navbar.scss";


function Navbar () {

  return(
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        <Link className="navbar-brand" to="/en"><img src={Logo} width="170" alt="Linkedin" /></Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/en/services">Services</Link>
            </li>
            <li className="nav-item dropdown_">
              <Link className="nav-link dropdown-toggle_" to="/en/products/" id="navbarDropdown_" role="button" data-toggle="dropdown_" aria-expanded="false">
                Products
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/en/solutions/">Solutions</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/en/about/">About</Link>
            </li>
            <li className="nav-item visible-md">
              <a className="nav-link" href="/en/#contact">Contact</a>
            </li>
          </ul>
          <a className="btn btn-primary my-2 my-lg-0 hidden-md" href="/en/#contact">
            Contact
          </a>
          <Link to="/" className="icon-lang">
            <img src={IconLang} width="28" alt="lang-icon" />
            ES
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar

import React from "react";
import { Link } from "gatsby"
import "../components/Footer.scss";
import Logo from "../images/logo-2.svg";
import IconLinkedin from "../images/icon-linkedin.svg";


function Footer(){
  return(
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <img src={Logo} width="110" className="mb-4" alt="Logo" />
            <p>
              Virtual Ed Global
              <br/><i>2021.</i>
            </p>
          </div>
          <div className="col-md-4">
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/en/services">Services</Link></li>
              <li><Link to="/en/products">Products</Link></li>
              <li><Link to="/en/solutions">Solutions</Link></li>
              <li><Link to="/en/about">About</Link></li>
            </ul>
          </div>
          <div className="col-md-4">
            <a href="https://www.linkedin.com/company/virtual-ed-global-edtech/" target="_blank" rel="noreferrer">
              <img src={IconLinkedin} alt="" className="mb-3" />
            </a>
            <p>
              Address: Calle General Gallegos, 3, 8D 
              <br/>CP: 28036, Madrid, España.
              <br/><a href="mailto:info@virtualedglobal.com">info@virtualedglobal.com</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
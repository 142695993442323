import React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const AboutBox = (props) => {
  const Bold = ({ children }) => <strong>{children}</strong>;
  const Text = ({ children }) => <p>{children}</p>;
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.LIST_ITEM]: (node, children) => {
        const UnTaggedChildren = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => children,
            [BLOCKS.LIST_ITEM]: (node, children) => children,
          },
        });

        return <li>{UnTaggedChildren}</li>;
      },
    },
  };

  const alignment = props.index % 2 ? "left" : "right";
  const boxClasses =
    alignment === "left" ? "col-md-7 about-box-texts order-md-2 left" : "col-md-7 about-box-texts order-md-1 right";
  const boxClasses2 =
    alignment === "left" ? "col-md-5 order-md-1 about-box-img pl-0" : "col-md-5 order-md-2 about-box-img";
  return (
    <div className="row align-items-center about-box">
      <div className={boxClasses}>
        <h2 className="">{props.title}</h2>
        {renderRichText(props.description, options)}
      </div>
      <div className={boxClasses2}>
        <img src={props.image} alt="" className="img-fluid" />
      </div>
    </div>
  );
};

export default AboutBox;

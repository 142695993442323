import React from "react";
import "../components/ContactForm.scss"
import HubspotForm from 'react-hubspot-form'

function ContactForm(props){
  let fieldPlaceholders = {
    title: 'Contacto',
  }

  if (props.lang === 'en') {
    fieldPlaceholders.title = 'Contact'
  }

  return(
    <div className="contact-form">
      <h2 className="title mb-5">{fieldPlaceholders.title}</h2>
      <HubspotForm
        portalId='21266580'
        formId='dfe4764d-0b6c-4f18-855e-ed683fcc16b7'
        /* onSubmit={() => console.log('Submit!')} */
        /* onReady={(form) => console.log('Form ready!')} */
        loading={<div>Loading...</div>}
      />
    </div>
  )

  /* Old Form using Netlify Forms */
  /* let fieldPlaceholders = {
    title: 'Contacto',
    name: 'Nombre',
    surname: 'Apellido',
    charge: 'Cargo dentro de la Institución',
    institutionName: 'Nombre de la institución',
    email: 'Correo corporativo',
    message: 'Mensaje',
    send: 'Enviar'
  }

  if (props.lang === 'en') {
    fieldPlaceholders.title = 'Contact'
    fieldPlaceholders.name = 'First name'
    fieldPlaceholders.surname = 'Last name'
    fieldPlaceholders.charge = 'Position within the institution'
    fieldPlaceholders.institutionName = 'Name of the institution'
    fieldPlaceholders.email = 'Institutional email'
    fieldPlaceholders.message = 'Message'
    fieldPlaceholders.send = 'Send'
  }

  return(
    <div className="contact-form">
      <h2 className="title">{fieldPlaceholders.title}</h2>
      <form method="post" data-netlify="true" name="Contact">
        <input type="hidden" name="Contact" value="Contact" />

        <div className="row">

          <div className="col-md-6">
            <div className="form-group">
              <input type="text" name="Nombre" placeholder={fieldPlaceholders.name} className="form-control" required />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <input type="text" name="Apellido" placeholder={fieldPlaceholders.surname} className="form-control" required />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <input type="text" name="Cargo dentro de la Institución" placeholder={fieldPlaceholders.charge} className="form-control" required />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <input type="text" name="Nombre de la institución" placeholder={fieldPlaceholders.institutionName} className="form-control" required />
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group">
              <input type="email" name="Correo corporativo" placeholder={fieldPlaceholders.email} className="form-control" required />
            </div>
          </div>

          <div className="col-12">
            <div className="form-group">
              <textarea name="Mensaje" className="form-control" placeholder={fieldPlaceholders.message}></textarea>
            </div>
          </div>

          <div className="col-12"><input type="submit" value={fieldPlaceholders.send} className="btn btn-primary" /></div>

        </div>
      </form>
    </div>
  ) */
}

export default ContactForm
import React from "react";
import "../components/HeroSection.scss";

function HeroSection(props) {
  return (
    <div className="hero-section">
      <div className="row align-items-center">
        <div className="col-lg-6 hero-section-img">
          <img src={props.image} alt="" className="img-fluid" />
        </div>
        <div className="col-lg-4 hero-section-texts">
          <h1>{props.title}</h1>
          {props.description.length > 0 && <p>{props.description}</p>}
        </div>
      </div>
    </div>
  );
}

export default HeroSection;

import React, {useState} from "react";
import "../components/Contact.scss";
import ContactForm from "../components/ContactForm"

function Contacto(props){
  const [showForm, setShowForm] = useState(false)

  return(
    <>
      <div className="cta-big-box">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              {props.title}
            </div>
            <div className="col-md-4 text-center">
              <button 
                className="btn btn-success btn-lg" 
                type="button" 
                onClick={() => setShowForm(!showForm)}>
                {props.button_label}
              </button>
            </div>
          </div>
        </div>
      </div>
      { showForm && 
        <div>
          <div className="container mb-5">
            <ContactForm {...props} />
          </div>
        </div>
      }
    </>
  )
}

export default Contacto